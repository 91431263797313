body {
  height: 100vh;
  margin: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-section {
  flex-grow: 1;
  overflow: auto;
  justify-content: center;
}

.footer {
  margin-top: auto;
}

